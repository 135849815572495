import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState, ManualsState } from "../types";
import EnvironmentManager from "@/environments/EnvironmentManager";
import { Schema } from "../models/schema.model";
import axios from "axios";

const state: ManualsState = {
    loading: false,
    schema: null
};

const getters: GetterTree<ManualsState, RootState> = {
  isLoading(state): boolean {
      return state.loading;
  },
  getSchema(state): object | null {
    return state.schema;
  }
};

const mutations: MutationTree<ManualsState> = {
  setLoading(state, value: boolean) {
      state.loading = value;
  },
  setSchema(state, schema: Schema) {
    state.schema = schema;
  }
};

const actions: ActionTree<ManualsState, RootState> = {
  /// ********** INIT: PARSE JSON **********
  parse(context) {
    // const manager = new EnvironmentManager();
    // TODO to replace with remote json handling
    const url = EnvironmentManager.URL_APP_MANUALS;

    context.commit("setLoading", true);
    axios.get(url).then((response: any) => {
        if(response.status == 200) {
            const schema = Schema.fromJson(response.data);
            context.commit("setSchema", schema);
            return schema;
        }
        throw response.error;
    }).finally(() => {
        context.commit("setLoading", false);
    });
  },
};

export const manualsModule: Module<ManualsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
