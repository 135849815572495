import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState, ConsentsState } from "../types";
import EnvironmentManager from "@/environments/EnvironmentManager";
import * as ApiService from '@/utils/api.service';

const state: ConsentsState = {
    loading: false,
};

const getters: GetterTree<ConsentsState, RootState> = {
  isLoading(state): boolean {
      return state.loading;
  },
};

const mutations: MutationTree<ConsentsState> = {
  setLoading(state, value: boolean) {
      state.loading = value;
  },
};

const actions: ActionTree<ConsentsState, RootState> = {
  /// ********** CONSENT LIST **********
  list(context, path): Promise<unknown> {
    const manager = new EnvironmentManager();
    const url = manager.authUrl() + path;

    context.commit("setLoading", true);
    return ApiService.get(url).catch((error: any) => {
        console.error(error);
        ApiService.throwError(error);
    }).finally(() => {
        context.commit("setLoading", false);
    });
  }
};

export const consentsModule: Module<ConsentsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
