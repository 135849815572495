<template>
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import * as ApiService from '@/utils/api.service';

export default defineComponent({
  name: 'AppComponent',
  components: { },

  computed: {
    ...mapGetters({
      'user': 'auth/getUser',
      'accessToken': 'auth/getToken',
    }),
  },

  mounted() {

    // if exists user in storage
    // init ApiService with access token
    if (this.user && this.accessToken) {
      const language = (navigator.language).substr(0, 2);
      const token = this.accessToken;
      ApiService.init(language, token);
    }
  }

});
</script>