import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/store/types';
import { consentsModule } from '@/store/modules/consents.module';
import { authModule } from './modules/auth.module';
import { manualsModule } from './modules/manuals.module';
import { errorModule } from './modules/error.module';
import VuexPersistence from 'vuex-persist';

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    auth: authModule,
    error: errorModule,
    consents: consentsModule,
    manuals: manualsModule,
  },
  
  plugins: [new VuexPersistence().plugin]
  
};

export default new Vuex.Store<RootState>(storeOptions);