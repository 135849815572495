import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store/index';
import BootstrapView from '@/views/BootstrapView.vue';
import { Error } from '@/store/models/error.model';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'bootstrap',
    component: BootstrapView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileView.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import(/* webpackChunkName: "documents" */ '@/views/DocumentsView.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '@/views/SupportView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to: any, from: any) => {

  const user = store.getters['auth/getUser'];
  // if user is not authenticated redirect to login route
  // check also the route name to avoid infinite loop
  if (!user && to.name !== 'login') {
    const error = new Error('errors_not_logged_in');
    store.commit('error/addError', error);
    return { name: 'login' }
  }

});

export default router