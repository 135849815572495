<template>
    <div class="loader" v-if="loading">
        <div class="loader-container" :class="{ 'full-page' : fullPage }">
            <img :src="Loader" height="32px" width="32px" />
            <div v-if="loaderText" class="loader-text">
                {{ loaderText }}
            </div>
        </div>
    </div>
</template>

<script>

import Loader from '@/assets/images/loader.svg';

export default {
    name: "LoaderComponent",
    data() {
        return {
            Loader,
        };
    },
    props: {
        loaderText: String,
        loading: {
            default: true,
            type: Boolean,
        },
        fullPage: {
            default: false,
            type: Boolean,
        },
    },
};
</script>
<style lang="scss" scoped>
.loader {

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;        

        &.full-page {
            position: fixed;
            z-index: 1000;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100vh;
            background-color: rgba(white, 0.9);
        }
    }

    .loader-text {
        margin-top: 1em;
    }
}
</style>