<template>
  
  <Loader fullPage loading />

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Loader from '@/components/commons/Loader.vue';

export default defineComponent({
  name: 'BootstrapView',
  components: { Loader },

  mounted() {
    if (this.user == null) this.$router.replace({ name: 'login'});
    else this.$router.replace({ name: 'home'});
  },

  computed: {
    ...mapGetters({
      'user': 'auth/getUser',
    }),
  },  
});
</script>

