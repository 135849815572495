export default class EnvironmentManager {

    static URL_AUTH_LOGIN = "/api/auth2/token/password";
    static URL_AUTH_LOGOUT = "/api/auth2/user/signout";
    static URL_AUTH_USER_INFO = "/api/auth2/user/info";
    static URL_AUTH_TOKEN_REFRESH = "/api/auth2/token/refresh";
    static URL_CONSENT_LIST = "/api/auth2/consent/list";
    static URL_APP_MANUALS = "app_manuals.json";

    static available_documents = [
        "app_user_manual", 
        "app_user_privacy", 
        "app_user_terms_conditions",
        "app_user_deletion_policy"
    ];
    
    private env: any;

    constructor() {
        this.env = require('./env.dev.json');
    }

    appClientId(): string {
        return this.env.appClientId;
    }

    appUrl(): string {
        return this.env.appUrl;
    }

    authUrl(): string {
        return this.env.authUrl;
    }

    phrUrl(): string {
        return this.env.phrUrl;
    }
}