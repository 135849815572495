import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState, AuthState } from "@/store/types";
import EnvironmentManager from "@/environments/EnvironmentManager";
import * as ApiService from '@/utils/api.service';

const state: AuthState = {
    loading: false,
    user: null,

    token: null,
    refreshToken: null,
    refreshAttempts: 0,
};

const getters: GetterTree<AuthState, RootState> = {
    isLoading(state): boolean {
        return state.loading;
    },
    getUser(state): object | null {
        return state.user;
    },
    getToken(state): string | null {
        return state.token;
    },
    getRefreshToken(state): string | null {
        return state.refreshToken;
    },
    getRefreshAttempts(state): number {
        return state.refreshAttempts;
    },
};

const mutations: MutationTree<AuthState> = {
    setLoading(state, value: boolean) {
        state.loading = value;
    },
    setUser(state, value: object) {
        state.user = value;
    },
    setToken(state, value: string | null) {
        state.token = value;
    },
    setRefreshToken(state, value: string | null) {
        state.refreshToken = value;
    },
    setRefreshAttempts(state, value: number) {
        state.refreshAttempts = value;
    },
};

const actions: ActionTree<AuthState, RootState> = {

    /// ********** LOGOUT **********
    logout(context): Promise<unknown> {
        const manager = new EnvironmentManager();
        const url = manager.authUrl() + EnvironmentManager.URL_AUTH_LOGOUT;

        context.commit("setLoading", true);
        return ApiService.post(url).catch((error: any) => {
            console.error(error);
            ApiService.throwError(error);
        }).finally(() => {
            context.commit("setUser", null);
            context.commit('setToken', null);
            context.commit("setRefreshToken", null);
            context.commit("setLoading", false);
        });
    },

    /// ********** LOGIN **********
    login(context, payload: unknown): Promise<unknown> {

        const manager = new EnvironmentManager();
        const url = manager.authUrl() + EnvironmentManager.URL_AUTH_LOGIN;
        const clientId = manager.appClientId();
        const headers = { "Authorization": clientId };

        context.commit("setLoading", true);
        return ApiService.post(url, payload, { 'headers': headers }).then((response: any) => {
            if (response && response.data && response.data.data && response.data.data.token) {

                const data = response.data.data;
                context.commit("setToken", data.token);
                context.commit("setRefreshToken", data.refresh);
                return data;
            }

            throw new Error("Token not found in response");

        }).catch((error: any) => {
            console.error(error);
            ApiService.throwError(error);
        }).finally(() => {
            context.commit("setLoading", false);
        });
    },

    /// ********** REFRESH TOKEN **********
    refreshToken(context) { 

        if (!context.getters.getRefreshToken) throw new Error("Refresh token not found");

        const manager = new EnvironmentManager();
        const url = manager.authUrl() + EnvironmentManager.URL_AUTH_TOKEN_REFRESH;
        const clientId = manager.appClientId();
        const headers = { "Authorization": clientId };
        const body = { "refresh": context.getters.getRefreshToken };

        context.commit("setLoading", true);
        return ApiService.post(url, body, { 'headers': headers }).then((response: any) => {

            if (response.status == 200 && (response.data.status == 0 || response.data.status == 200) && response.data && response.data.data && response.data.data.token) {
                const data = response.data.data;
                context.commit("setToken", data.token);
                context.commit("setRefreshToken", data.refresh);
                context.commit('setRefreshAttempts', 0);
                return data.token;
            }

            ApiService.throwError(response);

        }).catch((error: any) => {
            console.error(error);
            ApiService.throwError(error);
        }).finally(() => {
            context.commit("setLoading", false);
        });
    },

    /// ********** USER INFO **********
    getUserInfo(context): Promise<unknown> {

        const manager = new EnvironmentManager();
        const url = manager.authUrl() + EnvironmentManager.URL_AUTH_USER_INFO;

        context.commit("setLoading", true);
        return ApiService.get(url).then((response: any) => {
            if (response && response.data && (response.data.status == 0 || response.data.status == 200) && response.data.data) {
                const user = response.data.data;
                context.commit("setUser", user);
                return user;
            }

            ApiService.throwError(response);

        }).catch((error: any) => {
            console.error(error);
            ApiService.throwError(error);
        }).finally(() => {
            context.commit("setLoading", false);
        });
    }

};

export const authModule: Module<AuthState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
