import { createI18n } from 'vue-i18n';

const i18n = createI18n({  
  fallbackLocale: 'en',
  messages: {
    'en': require('./locales/en.json'),
    'it': require('./locales/it.json')
  }
});

export default i18n;