import EnvironmentManager from "@/environments/EnvironmentManager";
import { Document } from "./document.model";

export class Schema {
    documents: Array<Document> = [];

    static fromJson(dict: any): Schema {
        const schema = new Schema();
        const available_documents = EnvironmentManager.available_documents;
        available_documents.forEach((key: string) => {
            schema.documents.push(Document.fromJson(dict[key]))
        });
        return schema;
    }
}
  