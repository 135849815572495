/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState, ErrorState } from "../types";
import { Error } from '@/store/models/error.model';
import { Message } from '@/store/models/message.model';

const state: ErrorState = {
    errors: [],
    messages: [],
};

const getters: GetterTree<ErrorState, RootState> = {
    getErrors(state): Error[] | null {
        return state.errors;
    },
    getError: (state, getters) => (errorKey: string) => {
        if (!getters.getErrors) return null;
        return getters.getErrors.find((item: any) => {
            return errorKey == item.key
        });
    },
    getMessages(state): Message[] | null {
        return state.messages;
    },
    getMessage: (state, getters) => (messageKey: string) => {
        if (!getters.getMessages) return null;
        return getters.getMessages.find((item: any) => {
            return messageKey == item.key
        });
    }
};

const mutations: MutationTree<ErrorState> = {
    setErrors(state, value: Error[]) {
        state.errors = value;
    },
    addError(state, error: Error) {
        if (error == null) return;

        delError(state, error.key);
        
        if (!state.errors) state.errors = [];
        state.errors.push(error);
    },
    deleteError(state, errorKey: string) {
        delError(state, errorKey);
    },
    setMessages(state, value: Message[]) {
        state.messages = value;
    },
    addMessage(state, message: Message) {
        if (message == null) return;

        delMessage(state, message.key);
        
        if (!state.messages) state.messages = [];
        state.messages.push(message);
    },
    deleteMessage(state, messageKey: string) {
        delMessage(state, messageKey);
    }
};

const actions: ActionTree<ErrorState, RootState> = {
    clearErrors(context) {
        context.commit('setErrors', null);
    },
    clearMessages(context) {
        context.commit('setMessages', null);
    },
};

function delError(state: ErrorState, errorKey: string) {
    if (state.errors == null || state.errors.length == 0) return;
    const index = state.errors.findIndex((s: any) => { return errorKey == s.key });
    if (index >= 0) state.errors.splice(index, 1);
}

function delMessage(state: ErrorState, messageKey: string) {
    if (state.messages == null || state.messages.length == 0) return;
    const index = state.messages.findIndex((s: any) => { return messageKey == s.key });
    if (index >= 0) state.messages.splice(index, 1);
}

export const errorModule: Module<ErrorState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
