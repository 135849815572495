export class Document {
    type: DocumentType | undefined;
    //title: string | undefined;
    //description: string | undefined;
    //date: Date | undefined;
    supportedLocales: any | undefined;

    static fromJson(dict: any) : Document {
        const doc = new Document();
        doc.type = dict['type'];
        //doc.title = dict['title'];
        //doc.description = dict['description'];
        //doc.date = dict['date'];
        doc.supportedLocales = dict['supported_locales'];
        return doc;
    }

    /*
    src(locale: Locale): string {
        return this.supportedLocales[locale];
    }
    */
}

export enum DocumentType {
    pdf = 'pdf',
    api = 'api',
    url = 'url'
}