/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import store from '@/store/index';

const MAX_REFRESH_ATTEMPTS = 3;
const DEFAULT_REQUEST_TIMEOUT = 5 * 1000; // 5 seconds

export function init(language: string | null, token: string | null) {

    if (token == null) {
        console.error("Error: ApiService@init token not found");
        return;
    }

    // set header params as default for all http requests
    axios.defaults.headers.common['Accept'] = "application/json";
    axios.defaults.headers.common['Authorization'] = token;

    // ********** REQUEST INTERCEPTOR **********
    axios.interceptors.request.use((config) => {

        // Additional params that are always sent in requests
        const params = {
            //lang: (language) ? language : 'en',
        };

        let outParams = {
            ...params
        };

        // merge request params eith additional params
        if (config.params) {
            outParams = {
                ...config.params,
                ...params
            };
        }

        config.params = { ...outParams };

        config.timeout = DEFAULT_REQUEST_TIMEOUT;
        return config;

    }, (error) => {
        return Promise.reject(error);
    });

    // ********** RESPONSE INTERCEPTOR **********
    axios.interceptors.response.use(response => {
        // Do something with the response data
        return response;
    }, async (error) => {
        
        if (error.response && error.response.status && error.response.status == 401) {
            // Request failed with status code 401
            // try to refresh user token and reply the original request with the new token
            
            const refreshAttempts = store.getters['auth/getRefreshAttempts'];
            if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) 
                return Promise.reject(new Error("Max refresh attempts reached: " + refreshAttempts + ' of ' + MAX_REFRESH_ATTEMPTS));        
            
            store.commit('auth/setRefreshAttempts', refreshAttempts+1);
            const token = await store.dispatch('auth/refreshToken');
            axios.defaults.headers.common['Authorization'] = token;
    
            // se the new token inside the original request
            const newRequest = error.config;
            newRequest.headers.Authorization = token;
            
            // Retry the original request
            return axios(newRequest);
        }
        console.error(error);
        return Promise.reject(error);
    });
}

/*
let params = {
    "param1": "value",
    "params2": "value"
};
*/

export function get(endpoint: string, params?: any) {
    return axios.get(endpoint, params);
}
export function post(endpoint: string, params?: any, config?: any) {
    return axios.post(endpoint, params, config);
}
export function put(endpoint: string, params?: any) {
    return axios.put(endpoint, params);
}
export function del(endpoint: string) {
    return axios.delete(endpoint);
}

export function throwError(payload: any){
    if (payload == null) return;

    if (payload.response && payload.response.data && payload.response.data.error) throw new Error(payload.response.data.error);
    else if(payload.data && payload.error) throw new Error(payload.data.error);
    else if(payload.error) throw new Error(payload.error);
    else throw payload;
}


